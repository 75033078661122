/* hero */


.hero-heading{
    font-family: Noto Sans Thai;
    font-size: 20rem;
    font-weight: 500;
    /* line-height: 20px; */
    letter-spacing: 0.03em;
    color: #0097DB;
    /* height: 459px; */
}

/* .hero{
    align-items: center;
} */

.hero-para{
    font-size: 32px;
    color: #0097DB;
    padding-top: 10px;
}
.hero-what{
    font-family: Noto Serif JP;
    font-size: 48px;
    font-weight: 700;
    color: #0097DB;
    padding-top: 4rem;
}

.hero-paragraph{
    font-family: Noto Sans Thai;
    font-size: 28px;
    font-weight: 400;
    line-height: 42.31px;
    color: #636365;
    padding-bottom: 6rem;
    text-align: justify;
    
}
.hero-image{
    width: 100%;
height: 100%;
top: 1078px;
left: 100px;
gap: 0px;
}
.para{
    border: 1px solid #9D9D9D
}


/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .hero-heading {
        font-size: 10rem; /* Adjust font size for smaller screens */
        height: auto; /* Adjust height as needed */
    }
}



/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
    .hero-heading {
        font-size: 5rem; /* Further adjust font size for very small screens */
        height: auto; /* Adjust height as needed */
    }
}

.btnpara{
    color: #0097DB;
    font-weight: 600;
}


/* components */
.heading{
    font-family: Noto Serif JP;
    font-size: 44px;
    font-weight: 700;
        color: #0097DB;
}
.health-section{
   width: 100%;
   display: flex;
}
.health-left-sec{
    width: 100%;
    /* padding-left: 10%; */
}
.health-right-sec{
    width: 100%;
    padding-right: 10%;
}
.userApp-para{
    font-family: Noto Serif JP;
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    text-align:justify ;
}

.left-heading{
    font-family: Noto Serif JP;
    font-size: 32px;
    font-weight: 600;
    line-height: 45.98px;
    text-align: left;
    color: #0097DB; 
    line-height: 100px;
}
.health-para{
    width: Hug (467px)px;
    height: Hug (100px)px;
    gap: 4px;
    /* opacity: 0px; */
   text-align: justify;   
   line-height: 25px; 
   margin-bottom: 20px;
}
.health-btn{
    font-family: Noto Serif JP;
    font-size: 18px;
    font-weight: 500;
    line-height: 31px;
    float: left;
    color: #FFFFFF;
    background-color: #0097DB;
    border: none;
    /* width: 265px;
   height: 33px; */
   border-radius: 5%; 

}