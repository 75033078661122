.search-container {
  padding: 40px 0;
  /* background: #f0f7ff; Light background color to match the screenshot */
}

.search-header h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}
.second input {
  width: 100%;
}
.searchmain {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* background: white; */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.first,
.second {
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 5px;
  margin-right: 10px;
}

.input-location,
.input-search {
  border: none;
  outline: none;
  padding: 10px;
  width: 200px;
}

.input-location::placeholder,
.input-search::placeholder {
  color: #aaa;
}

.search-button {
  position: absolute;
  right: 6%;
  padding: 5px 8px;
  background: #0097db !important;
  font-family: "Gilroy-SemiBold";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  border-radius: 5px;
  color: white;
}

.prescription {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.prescription-link {
  margin-left: 10px;
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
}

.prescription-link:hover {
  text-decoration: underline;
}
