@font-face {
    font-family: "Gilroy-SemiBold";
    src: url("../Assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
}

/* For responsive flex-row layout */
@media (min-width: 768px) {
    .md\:flex-row {
        flex-direction: row;
        min-height: auto; /* Ensures full viewport height on larger screens */
        align-items: center; /* Center items vertically */
    }
}

/* Styling for the main text "Avijo - One Stop HealthApp" */
#avijo-text {
    margin-left: 27px;
    font-size: 40px;
    text-align: start;
    width: 90%;
    font-family: "Gilroy-SemiBold";
    margin-top: 0 !important; /* Reduced top space */
    padding: 5 1rem !important; /* Added padding for small screens */
    margin-bottom: 3.5rem;
}

/* Styling for the heading "India's first Instant home healthcare partner" */
#heading-home {
    font-size: 54px;
    color: rgb(0, 151, 219) !important;
    font-family: "Gilroy-SemiBold";
    text-align: left;
    margin-top: 0 !important; /* Reduced top space */
    padding: 0 1rem !important; /* Added padding for small screens */
    margin-bottom: 2rem;
}

/* Paragraph styling */
#para-health {
    margin-top: -3rem;
    margin-left: 30px;
    font-size: 18px;
    color: #525151bf !important;
    font-family: "Gilroy-SemiBold";
    text-align: start;
    width: 90%;
    margin-bottom: 20px;
    margin-bottom: 0.5rem;
}
.container.button {
    width: 70%;
    margin-top: 5%;
    margin-left:5px !important;
   
}

.button-container {
    margin-left:12px !important;
    padding: 10px;
    align-items: self-start !important;
    width: 160px;
    border-radius: 9999px;
    background-color: #0097DB;
    text-align: center;  /* Aligns button to the left */
    margin-bottom: 3rem !important;
}

.download-button {
    color: white;
    align-items: self-start !important;
    padding: 5px;
    border-radius: 5px;
    /* Add more styling as required */
}
#download-button{
    padding:-1rem !important;
    margin: 0% !important;
}

/* Styling for the bullet points */
.home-paragraph {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.744);
    font-family: "Gilroy-SemiBold";
    margin: 0 auto;
    width: 90%;
    text-align: left;
}

/* Flex container adjustments */
.flex-row > .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.get-link-container{
    
    font-size: 16px;
    color: #323232cd;
    font-family: "Gilroy-SemiBold";
    margin: 0 auto;
    width: 90%;
    text-align: left;
    margin-bottom: 3rem !important;
}

#send-sms-button {
    width: 100%;
    max-width: 150px;
    font-size: 14px;
    padding: 10px;
    border-radius: 4px;
    background-color: #0097DB;
    color: white;
    font-family: "Gilroy-SemiBold";
    cursor: pointer;
}


/* Footer styling */
footer {
    width: 100%;
    text-align: center;
    background-color: white;
    padding: 1rem 1rem;
    font-size: 14px;
    color: #323232;
    font-family: "Gilroy-SemiBold";
}

/* Ensure no extra space at the top */
body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    padding: 2rem; /* Add consistent spacing at the top */
}
